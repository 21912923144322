import Head from 'next/head';
import React from 'react';
import NotFoundComponent from '@lepass/components/Errors/NotFound'


const NotFound = () => (
    <>
        <Head>
            <title>404</title>
        </Head>
        <NotFoundComponent />
    </>
);

export default NotFound;
